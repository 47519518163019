import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import MainNav from "./mainnav"
import * as style from "./header.module.scss"

const Header = ({ siteAuthorSummary, menuLinks }) => (
  <header id="site-header" className={style.masthead} role="banner">
    <div className={style.container}>
      <div className={style.siteinfo}>
        <Link to="/">
          <svg
            id="site_logo"
            className={style.site_logo}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 4450 1000"
          >
            <title className={style.site_title}>site logo</title>
            <g transform="matrix(1.07573,0,0,1.37854,1882.37,-89.5383)">
              <rect
                className={style.lastname_rectangle}
                x="243.851"
                y="96.82"
                width="2105.66"
                height="661.719"
              />
            </g>
            <g transform="matrix(1,0,0,1.37854,-204.818,-89.5383)">
              <rect
                className={style.firstname_rectangle}
                x="243.851"
                y="96.82"
                width="2105.66"
                height="661.719"
              />
            </g>
            <g transform="matrix(1,0,0,1,1092.18,501.631)">
              <g transform="matrix(0.9,0,0,1,108.714,0)">
                <g transform="matrix(759.332,0,0,759.332,1087.14,236.123)">
                  <path
                    className={style.lastletter}
                    d="M0.945,-0L0.613,-0L0.613,-0.045C0.641,-0.045 0.662,-0.048 0.676,-0.053C0.689,-0.059 0.698,-0.067 0.702,-0.078C0.706,-0.089 0.708,-0.104 0.708,-0.123L0.708,-0.538L0.458,-0L0.428,-0L0.175,-0.538L0.175,-0.144C0.175,-0.123 0.176,-0.107 0.177,-0.096C0.178,-0.085 0.182,-0.076 0.188,-0.068C0.194,-0.06 0.204,-0.054 0.217,-0.051C0.23,-0.047 0.248,-0.045 0.271,-0.045L0.271,-0L0.026,-0L0.026,-0.045C0.049,-0.045 0.067,-0.047 0.08,-0.051C0.093,-0.055 0.103,-0.061 0.108,-0.068C0.114,-0.076 0.118,-0.086 0.119,-0.096C0.12,-0.107 0.121,-0.123 0.121,-0.144L0.121,-0.519C0.121,-0.547 0.12,-0.567 0.117,-0.58C0.114,-0.592 0.106,-0.601 0.093,-0.608C0.079,-0.614 0.057,-0.617 0.026,-0.617L0.026,-0.662L0.268,-0.662L0.488,-0.195L0.708,-0.662L0.945,-0.662L0.945,-0.617C0.904,-0.617 0.878,-0.611 0.866,-0.599C0.854,-0.587 0.848,-0.567 0.848,-0.539L0.848,-0.123C0.848,-0.105 0.85,-0.091 0.854,-0.08C0.858,-0.069 0.867,-0.06 0.881,-0.054C0.895,-0.048 0.917,-0.045 0.945,-0.045L0.945,-0Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,169.485,0)">
                <g transform="matrix(759.332,0,0,759.332,1694.85,236.123)">
                  <path
                    className={style.lastletter}
                    d="M0.522,-0.201C0.522,-0.157 0.511,-0.118 0.49,-0.085C0.468,-0.053 0.439,-0.027 0.403,-0.01C0.366,0.007 0.326,0.016 0.282,0.016C0.241,0.016 0.202,0.008 0.166,-0.008C0.129,-0.024 0.099,-0.048 0.076,-0.081C0.053,-0.113 0.041,-0.153 0.041,-0.2C0.041,-0.271 0.065,-0.324 0.113,-0.361C0.161,-0.398 0.219,-0.416 0.285,-0.416C0.335,-0.416 0.377,-0.406 0.413,-0.387C0.449,-0.367 0.476,-0.341 0.494,-0.308C0.513,-0.275 0.522,-0.24 0.522,-0.201ZM0.387,-0.204C0.387,-0.259 0.38,-0.301 0.365,-0.329C0.351,-0.357 0.322,-0.371 0.28,-0.371C0.253,-0.371 0.232,-0.365 0.217,-0.353C0.201,-0.341 0.191,-0.322 0.185,-0.296C0.179,-0.271 0.176,-0.237 0.176,-0.197C0.176,-0.147 0.182,-0.106 0.196,-0.075C0.209,-0.044 0.238,-0.029 0.283,-0.029C0.313,-0.029 0.335,-0.037 0.351,-0.054C0.366,-0.072 0.376,-0.093 0.381,-0.118C0.385,-0.143 0.387,-0.172 0.387,-0.204Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,203.517,0)">
                <g transform="matrix(759.332,0,0,759.332,2035.17,236.123)">
                  <path
                    className={style.lastletter}
                    d="M0.801,-0.355C0.786,-0.355 0.775,-0.353 0.767,-0.35C0.76,-0.347 0.753,-0.341 0.747,-0.332C0.742,-0.324 0.734,-0.308 0.723,-0.286L0.579,0.016L0.537,0.016L0.404,-0.247L0.274,0.016L0.232,0.016L0.091,-0.285C0.077,-0.315 0.066,-0.335 0.057,-0.343C0.049,-0.351 0.033,-0.355 0.011,-0.355L0.011,-0.4L0.262,-0.4L0.262,-0.355L0.249,-0.355C0.224,-0.355 0.212,-0.347 0.212,-0.331C0.212,-0.323 0.215,-0.312 0.221,-0.299L0.298,-0.134L0.43,-0.4L0.465,-0.4L0.599,-0.134L0.671,-0.286C0.681,-0.309 0.686,-0.324 0.686,-0.331C0.686,-0.342 0.682,-0.348 0.673,-0.351C0.664,-0.354 0.65,-0.355 0.629,-0.355L0.629,-0.4L0.801,-0.4L0.801,-0.355Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,259.76,0)">
                <g transform="matrix(759.332,0,0,759.332,2597.6,236.123)">
                  <path
                    className={style.lastletter}
                    d="M0.441,-0.339C0.441,-0.32 0.435,-0.305 0.424,-0.294C0.412,-0.283 0.398,-0.278 0.383,-0.278C0.364,-0.278 0.348,-0.287 0.334,-0.305C0.327,-0.313 0.322,-0.319 0.318,-0.323C0.314,-0.326 0.308,-0.328 0.3,-0.328C0.274,-0.328 0.255,-0.314 0.24,-0.287C0.226,-0.259 0.219,-0.231 0.219,-0.201L0.219,-0.116C0.219,-0.089 0.223,-0.07 0.23,-0.06C0.237,-0.05 0.255,-0.045 0.285,-0.045L0.311,-0.045L0.311,-0L0.008,-0L0.008,-0.045L0.033,-0.045C0.061,-0.045 0.077,-0.05 0.083,-0.061C0.09,-0.071 0.093,-0.09 0.093,-0.116L0.093,-0.259C0.093,-0.281 0.091,-0.296 0.086,-0.306C0.082,-0.315 0.074,-0.321 0.063,-0.324C0.051,-0.328 0.033,-0.329 0.008,-0.329L0.008,-0.374L0.198,-0.416C0.203,-0.399 0.208,-0.364 0.212,-0.31C0.221,-0.329 0.233,-0.347 0.248,-0.363C0.263,-0.379 0.279,-0.392 0.298,-0.401C0.316,-0.411 0.335,-0.416 0.354,-0.416C0.381,-0.416 0.402,-0.409 0.417,-0.394C0.433,-0.379 0.441,-0.36 0.441,-0.339Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,291.059,0)">
                <g transform="matrix(759.332,0,0,759.332,2910.59,236.123)">
                  <path
                    className={style.lastletter}
                    d="M0.519,-0.355C0.505,-0.353 0.494,-0.349 0.486,-0.344C0.479,-0.339 0.471,-0.331 0.465,-0.32C0.459,-0.309 0.447,-0.287 0.431,-0.254L0.288,0.043C0.256,0.108 0.234,0.152 0.223,0.174C0.211,0.195 0.198,0.212 0.184,0.225C0.169,0.238 0.151,0.244 0.129,0.244C0.114,0.244 0.101,0.24 0.09,0.23C0.079,0.221 0.073,0.21 0.073,0.196C0.073,0.176 0.08,0.162 0.094,0.153C0.107,0.144 0.125,0.136 0.146,0.13C0.175,0.121 0.197,0.106 0.212,0.083C0.228,0.061 0.241,0.036 0.252,0.01L0.066,-0.318C0.057,-0.334 0.049,-0.344 0.042,-0.348C0.035,-0.353 0.025,-0.355 0.012,-0.355L0.012,-0.4L0.249,-0.4L0.249,-0.355L0.234,-0.355C0.211,-0.355 0.2,-0.35 0.2,-0.339C0.2,-0.332 0.205,-0.321 0.214,-0.305L0.317,-0.124L0.393,-0.279C0.404,-0.301 0.409,-0.316 0.409,-0.324C0.409,-0.334 0.404,-0.342 0.395,-0.347C0.385,-0.352 0.374,-0.355 0.361,-0.355L0.361,-0.4L0.519,-0.4L0.519,-0.355Z"
                  />
                </g>
              </g>
            </g>
            <g transform="matrix(1,0,0,1,-1014.83,501.631)">
              <g transform="matrix(0.9,0,0,1,108.714,0)">
                <g transform="matrix(759.332,0,0,759.332,1087.14,236.123)">
                  <path
                    className={style.firstletter}
                    d="M0.724,-0.325C0.724,-0.277 0.716,-0.234 0.7,-0.196C0.683,-0.157 0.662,-0.125 0.637,-0.099C0.611,-0.073 0.583,-0.054 0.554,-0.041C0.492,-0.014 0.417,-0 0.327,-0L0.025,-0L0.025,-0.045C0.053,-0.045 0.074,-0.048 0.088,-0.053C0.103,-0.058 0.112,-0.066 0.116,-0.077C0.121,-0.088 0.123,-0.103 0.123,-0.123L0.123,-0.541C0.123,-0.57 0.117,-0.59 0.105,-0.602C0.094,-0.613 0.067,-0.619 0.025,-0.619L0.025,-0.665L0.167,-0.668L0.287,-0.67C0.341,-0.67 0.388,-0.667 0.426,-0.661C0.463,-0.655 0.498,-0.646 0.53,-0.633C0.559,-0.621 0.585,-0.605 0.61,-0.584C0.634,-0.563 0.655,-0.539 0.672,-0.512C0.688,-0.485 0.701,-0.456 0.71,-0.424C0.72,-0.393 0.724,-0.36 0.724,-0.325ZM0.566,-0.328C0.566,-0.397 0.553,-0.454 0.527,-0.501C0.5,-0.547 0.465,-0.579 0.42,-0.597C0.4,-0.605 0.38,-0.61 0.358,-0.612C0.336,-0.614 0.304,-0.615 0.263,-0.615L0.263,-0.15C0.263,-0.123 0.264,-0.104 0.267,-0.092C0.27,-0.079 0.277,-0.07 0.29,-0.064C0.303,-0.057 0.322,-0.054 0.349,-0.054C0.428,-0.054 0.484,-0.082 0.517,-0.137C0.55,-0.193 0.566,-0.256 0.566,-0.328Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,157.987,0)">
                <g transform="matrix(759.332,0,0,759.332,1579.87,236.123)">
                  <path
                    className={style.firstletter}
                    d="M0.475,-0.215L0.175,-0.215C0.175,-0.209 0.176,-0.195 0.177,-0.175C0.179,-0.155 0.184,-0.134 0.191,-0.113C0.199,-0.092 0.212,-0.074 0.231,-0.06C0.249,-0.045 0.275,-0.038 0.306,-0.038C0.323,-0.038 0.344,-0.042 0.369,-0.051C0.393,-0.06 0.414,-0.077 0.432,-0.102L0.47,-0.076C0.432,-0.015 0.371,0.016 0.287,0.016C0.25,0.016 0.216,0.011 0.185,-0C0.154,-0.011 0.128,-0.026 0.106,-0.045C0.085,-0.064 0.069,-0.087 0.057,-0.113C0.046,-0.139 0.04,-0.167 0.04,-0.197C0.04,-0.236 0.05,-0.273 0.069,-0.306C0.088,-0.34 0.115,-0.366 0.15,-0.386C0.186,-0.406 0.226,-0.416 0.272,-0.416C0.317,-0.416 0.354,-0.406 0.385,-0.387C0.416,-0.368 0.438,-0.343 0.453,-0.313C0.468,-0.282 0.475,-0.249 0.475,-0.215ZM0.354,-0.26C0.354,-0.334 0.325,-0.371 0.266,-0.371C0.238,-0.371 0.216,-0.361 0.201,-0.34C0.186,-0.32 0.177,-0.293 0.176,-0.26L0.354,-0.26Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,188.382,0)">
                <g transform="matrix(759.332,0,0,759.332,1883.82,236.123)">
                  <path
                    className={style.firstletter}
                    d="M0.555,-0.203C0.555,-0.172 0.549,-0.143 0.538,-0.115C0.527,-0.088 0.511,-0.065 0.49,-0.045C0.469,-0.025 0.446,-0.01 0.419,0C0.393,0.011 0.365,0.016 0.335,0.016C0.3,0.016 0.27,0.009 0.245,-0.004C0.22,-0.018 0.199,-0.035 0.183,-0.056C0.153,-0.026 0.132,-0.002 0.118,0.016L0.08,0.016L0.08,-0.525C0.08,-0.543 0.078,-0.557 0.073,-0.566C0.069,-0.574 0.062,-0.58 0.052,-0.582C0.041,-0.585 0.025,-0.586 0.004,-0.587L0.004,-0.631L0.206,-0.674L0.206,-0.358C0.223,-0.374 0.242,-0.387 0.264,-0.399C0.286,-0.41 0.315,-0.416 0.35,-0.416C0.383,-0.416 0.413,-0.41 0.439,-0.398C0.465,-0.386 0.486,-0.37 0.503,-0.349C0.521,-0.329 0.534,-0.306 0.542,-0.281C0.551,-0.256 0.555,-0.23 0.555,-0.203ZM0.42,-0.207C0.42,-0.248 0.413,-0.284 0.4,-0.315C0.386,-0.346 0.36,-0.362 0.32,-0.362C0.306,-0.362 0.291,-0.359 0.278,-0.353C0.264,-0.348 0.251,-0.34 0.239,-0.33C0.227,-0.32 0.216,-0.309 0.206,-0.296L0.206,-0.097C0.226,-0.072 0.245,-0.056 0.264,-0.049C0.284,-0.042 0.301,-0.038 0.316,-0.038C0.343,-0.038 0.365,-0.047 0.38,-0.065C0.396,-0.083 0.406,-0.105 0.412,-0.13C0.417,-0.155 0.42,-0.181 0.42,-0.207Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,224.209,0)">
                <g transform="matrix(759.332,0,0,759.332,2242.09,236.123)">
                  <path
                    className={style.firstletter}
                    d="M0.555,-0.203C0.555,-0.172 0.549,-0.143 0.538,-0.115C0.527,-0.088 0.511,-0.065 0.49,-0.045C0.469,-0.025 0.446,-0.01 0.419,0C0.393,0.011 0.365,0.016 0.335,0.016C0.3,0.016 0.27,0.009 0.245,-0.004C0.22,-0.018 0.199,-0.035 0.183,-0.056C0.153,-0.026 0.132,-0.002 0.118,0.016L0.08,0.016L0.08,-0.525C0.08,-0.543 0.078,-0.557 0.073,-0.566C0.069,-0.574 0.062,-0.58 0.052,-0.582C0.041,-0.585 0.025,-0.586 0.004,-0.587L0.004,-0.631L0.206,-0.674L0.206,-0.358C0.223,-0.374 0.242,-0.387 0.264,-0.399C0.286,-0.41 0.315,-0.416 0.35,-0.416C0.383,-0.416 0.413,-0.41 0.439,-0.398C0.465,-0.386 0.486,-0.37 0.503,-0.349C0.521,-0.329 0.534,-0.306 0.542,-0.281C0.551,-0.256 0.555,-0.23 0.555,-0.203ZM0.42,-0.207C0.42,-0.248 0.413,-0.284 0.4,-0.315C0.386,-0.346 0.36,-0.362 0.32,-0.362C0.306,-0.362 0.291,-0.359 0.278,-0.353C0.264,-0.348 0.251,-0.34 0.239,-0.33C0.227,-0.32 0.216,-0.309 0.206,-0.296L0.206,-0.097C0.226,-0.072 0.245,-0.056 0.264,-0.049C0.284,-0.042 0.301,-0.038 0.316,-0.038C0.343,-0.038 0.365,-0.047 0.38,-0.065C0.396,-0.083 0.406,-0.105 0.412,-0.13C0.417,-0.155 0.42,-0.181 0.42,-0.207Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,260.718,0)">
                <g transform="matrix(759.332,0,0,759.332,2607.18,236.123)">
                  <path
                    className={style.firstletter}
                    d="M0.234,-0.607C0.234,-0.587 0.227,-0.571 0.213,-0.557C0.199,-0.543 0.183,-0.536 0.163,-0.536C0.144,-0.536 0.127,-0.543 0.113,-0.557C0.099,-0.572 0.092,-0.588 0.092,-0.607C0.092,-0.626 0.099,-0.643 0.113,-0.657C0.127,-0.671 0.144,-0.678 0.163,-0.678C0.183,-0.678 0.2,-0.671 0.213,-0.658C0.227,-0.644 0.234,-0.627 0.234,-0.607ZM0.289,-0L0.024,-0L0.024,-0.045L0.038,-0.045C0.057,-0.045 0.071,-0.048 0.079,-0.053C0.088,-0.058 0.093,-0.065 0.095,-0.075C0.097,-0.084 0.098,-0.098 0.098,-0.116L0.098,-0.261C0.098,-0.281 0.097,-0.296 0.094,-0.304C0.092,-0.313 0.086,-0.319 0.075,-0.323C0.065,-0.327 0.048,-0.329 0.024,-0.329L0.024,-0.374L0.224,-0.416L0.224,-0.116C0.224,-0.089 0.228,-0.07 0.234,-0.06C0.241,-0.05 0.259,-0.045 0.289,-0.045L0.289,-0Z"
                  />
                </g>
              </g>
              <g transform="matrix(0.9,0,0,1,277.666,0)">
                <g transform="matrix(759.332,0,0,759.332,2776.66,236.123)">
                  <path
                    className={style.firstletter}
                    d="M0.475,-0.215L0.175,-0.215C0.175,-0.209 0.176,-0.195 0.177,-0.175C0.179,-0.155 0.184,-0.134 0.191,-0.113C0.199,-0.092 0.212,-0.074 0.231,-0.06C0.249,-0.045 0.275,-0.038 0.306,-0.038C0.323,-0.038 0.344,-0.042 0.369,-0.051C0.393,-0.06 0.414,-0.077 0.432,-0.102L0.47,-0.076C0.432,-0.015 0.371,0.016 0.287,0.016C0.25,0.016 0.216,0.011 0.185,-0C0.154,-0.011 0.128,-0.026 0.106,-0.045C0.085,-0.064 0.069,-0.087 0.057,-0.113C0.046,-0.139 0.04,-0.167 0.04,-0.197C0.04,-0.236 0.05,-0.273 0.069,-0.306C0.088,-0.34 0.115,-0.366 0.15,-0.386C0.186,-0.406 0.226,-0.416 0.272,-0.416C0.317,-0.416 0.354,-0.406 0.385,-0.387C0.416,-0.368 0.438,-0.343 0.453,-0.313C0.468,-0.282 0.475,-0.249 0.475,-0.215ZM0.354,-0.26C0.354,-0.334 0.325,-0.371 0.266,-0.371C0.238,-0.371 0.216,-0.361 0.201,-0.34C0.186,-0.32 0.177,-0.293 0.176,-0.26L0.354,-0.26Z"
                  />
                </g>
              </g>
            </g>
          </svg>
          <div className={style.tagline}>{siteAuthorSummary}</div>
        </Link>
      </div>

      <MainNav menuLinks={menuLinks} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
