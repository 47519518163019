import React from "react"

import * as style from "./footer.module.scss"

const Footer = ({ siteTitle }) => (
  <footer className={style.colophon}>
    © {new Date().getFullYear()} {siteTitle}
  </footer>
)

export default Footer
