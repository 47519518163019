// extracted by mini-css-extract-plugin
export var masthead = "header-module--masthead--cBCbl";
export var container = "header-module--container--i1m8L";
export var siteinfo = "header-module--siteinfo--UzjjK";
export var site_logo = "header-module--site_logo--Kup3+";
export var tagline = "header-module--tagline--zKurl";
export var site_title = "header-module--site_title--P6VNm";
export var masthead_nav = "header-module--masthead_nav--4sEmT";
export var firstletter = "header-module--firstletter--fLR8M";
export var lastletter = "header-module--lastletter--ZXZ96";
export var lastname_rectangle = "header-module--lastname_rectangle--MkPUF";
export var firstname_rectangle = "header-module--firstname_rectangle--Kycw4";